<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8 work-modal"
        dialog-class="wameed-modal-md"
        no-fade
        :key="key"
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ $t('settings.work.new') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md="6">
          <Wameed-date-picker
              v-model="form.open_time"
              placeholder="--:--"
              :config='{"type":"string","mask":"H:mm"}'
              mode="time"
              :label="$t('settings.work.form.start')"
              rules="required"

              :updateOnInput="true"
          />
        </b-col>

        <b-col md="6">
          <Wameed-date-picker
              v-model="form.close_time"
              placeholder="--:--"
              :config='{"type":"string","mask":"H:mm"}'
              mode="time"
              :label="$t('settings.work.form.end')"
              rules="required"
              :updateOnInput="true"
          />
        </b-col>
      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="$t('btn.add')"
            :disabled="invalid"

            type="submit"
            variant="main"
            @onClick="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver} from 'vee-validate';
import {WameedBtn, WameedDatePicker} from 'wameed-ui/dist/wameed-ui.esm';
import {mapActions} from 'vuex';


export default {
  components: {
    ValidationObserver,
    WameedDatePicker,
    WameedBtn,
  },
  props: {
    day: 0,
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      key:0,
      form: {
        day: 0,
        open_time: '',
        close_time: ''
      },
      showSuccessModal: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },


  methods: {
    ...mapActions({
      create: "vendor/workDays/create",
    }),

    submitOrder() {
      this.form.day = this.day;
      this.form.vendor_id = this.$route.params.id;
      this.create(this.form).then(() => {
        this.$emit('onComplete', true);
        this.show = false;
        this.showModal = true;
        this.form = {
          day: 0,
          open_time: '',
          close_time: ''
        };
        this.$store.commit('loadingFinish', null, {root: true});
      }).catch(() => {
        this.show = false;
        this.showModal = true;
        this.form = {
          day: 0,
          open_time: '',
          close_time: ''
        };
      });


    },

    closeModal() {

      this.show = false;
    },
  },
};
</script>
