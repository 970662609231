<template>

  <div class="work-card">
    <div class="work-card__header">
      <div class="work-card__header_info">

        <div class="title">{{ $t('days.' + title) }}</div>
        <div v-if="day.status===1">
          <span class="status open"> {{ $t('days.open') }} </span>
          -
          <span>{{ $t('settings.work_note') }}</span>
          <span class="status open"> 24 </span>
          <span>{{ $t('settings.hours') }}</span>
        </div>
        <div v-else>
          <span class="status close"> {{ $t('days.close') }} </span>
        </div>
      </div>
      <div class="switch">
        <WameedSwitch
            :checked="day.status ===1"
            @onChange="toggleStatus"
        />
      </div>
    </div>

    <div class="work-card__body" v-if=" day.status ===1">

      <div class="time-card" v-for="day in day.shifts" :key="day.id" v-if="day.open_time">
        <span>{{ formatTime(day.open_time) }}</span>
        -
        <span>{{ formatTime(day.close_time) }}</span>

        <div class="time-card-delete" @click="$emit('onDelete',day.id)">
          <close-icon class="size-12"/>
        </div>
      </div>

      <div class="time-card add" v-if="shiftsLength<3" @click="$emit('onAdd')">
        <div class="time-card-add">
          <add-filled-circle-icon/>
        </div>
        <span>{{ $t('settings.work.new') }}</span>
      </div>

    </div>

  </div>
</template>

<script>
import {WameedSwitch} from 'wameed-ui/dist/wameed-ui.esm'

import moment from 'moment';

export default {
  name: "workTimeCard",
  components: {
    WameedSwitch
  },

  props: {
    day: null,
    title: null,
  },
  computed: {
    shiftsLength() {
      return Object.values(this.day.shifts).filter((day) => {
        if (day.open_time) {
          return day;
        }
      }).length
    }
  },
  methods: {
    getDay() {
      switch (this.title) {
        case 'sat':
          return 1;
        case 'sun':
          return 2;
        case 'mon':
          return 3;
        case 'tue':
          return 4;
        case 'wed':
          return 5;
        case 'thur':
          return 6;
        default:
          return 7;
      }
    },

    formatTime(time) {

      moment.locale(this.$store.getters['getCurrentLocale']);
      return moment(time, 'h:mm:ss').format('hh:mm A').ArtoEn().AMPM();
    },
    toggleStatus() {

      let status = this.day.status === 1 ? 0 : 1;
      let form = {
        day: this.getDay(),
        status: status,
        vendor_id: this.$route.params.id
      }
      this.$store.dispatch('vendor/workDays/toggle', form);
    }
  }
}
</script>

<style scoped>

</style>
